import React from "react"

import ComponentSignup from "../components/sections/SectionSignup/ComponentSignup"
import SectionSignUp from "../components/sections/section-signup/SectionSignUp"
import Schema from "../components/schema"
import Seo from "../components/seoGlobal"
import { useLocale } from "../hooks"

const PageSignUp = ({ location, pageContext }) => {
  const locale = useLocale()
  return (
    <>
      <Seo lang={locale} title={"Sign Up Page"} />
      <Schema />
      {locale === "en-US" ? <ComponentSignup /> : <SectionSignUp />}
    </>
  )
}

export default PageSignUp
