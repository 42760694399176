/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { useLocale, useSearchQuery, useSiteQuery, useUrl } from "../hooks"
import { useLocation } from '@reach/router';
import { siteUrl, mapLangSelector } from "../utils/mapping"

const Seo = ({
    description = null,
    lang = null,
    meta = null,
    title = null,
    canonical = null,
    slick = false,
    hrefLangs = [],
    verificationId = null,
    noIndex = false,
    wtb = false
}) => {
    const { site } = useStaticQuery(
        graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
    )

    const location = useLocation()
    const baseUrl = useUrl()
    const { seoImageGlobal } = useSiteQuery()
    const {  wtbMetaName,storeLocatorMetaContent } = useSearchQuery()

    const metaDescription = description || site.siteMetadata.description
    const defaultTitle = site.siteMetadata?.title
    // const canonicalSlug = `${origin}/${baseUrl}/${canonical}`
    const locale = useLocale()
    return (
        <>
            <Helmet
                htmlAttributes={{
                    lang,
                }}
                title={title}
                titleTemplate={defaultTitle ? `%s` : null}
                meta={[
                    {
                        name: `description`,
                        content: metaDescription,
                    },
                    {
                        property: `og:title`,
                        content: title,
                    },
                    {
                        property: `og:description`,
                        content: metaDescription,
                    },
                    {
                        property: `og:type`,
                        content: `website`,
                    },
                    {
                        property: `og:image`,
                        content: ``,
                    },
                    // {
                    //     property: 'og:url',
                    //     content: href
                    // },
                    {
                        property: `og:image:secure_url`,
                        content: ``,
                    },
                    {
                        property: `og:image:type`,
                        content: `image/jpeg`,
                    },
                    {
                        property: `og:image:width`,
                        content: `400`,
                    },
                    {
                        property: `og:image:height`,
                        content: `300`,
                    },
                    {
                        property: `og:image:alt`,
                        content: ``,
                    },
                    {
                        name: `twitter:card`,
                        content: `summary`,
                    },
                    {
                        name: `twitter:creator`,
                        content: site.siteMetadata?.author || ``,
                    },
                    {
                        name: `twitter:title`,
                        content: title,
                    },
                    {
                        name: `twitter:description`,
                        content: metaDescription,
                    },
                    {
                        name: wtbMetaName,
                        content: storeLocatorMetaContent,
                      },
                ].concat(meta)}
            />
            {
                verificationId
                    ? <Helmet>
                        <meta name="google-site-verification" content={verificationId} />
                    </Helmet>
                    : null
            }
            {/* {
                slick
                    ? <Helmet>
                        <link key="slick-carousel" rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.css" integrity="sha512-wR4oNhLBHf7smjy0K4oqzdWumd+r5/+6QO/vDda76MW5iug4PT7v86FoEkySIJft3XA0Ae6axhIvHrqwm793Nw==" crossOrigin="anonymous" referrerPolicy="no-referrer" defer />,
                        <link key="slick-carousel-theme" rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick-theme.css" integrity="sha512-6lLUdeQ5uheMFbWm3CP271l14RsX1xtx+J5x2yeIDkkiBpeVTNhTqijME7GgRKKi6hCqovwCoBTlRBEC20M8Mg==" crossOrigin="anonymous" referrerPolicy="no-referrer" defer />
                        <meta name="ps-key" content={wtb ? "1750-621d732943383f0178804f26" : "1750-5dd55ca1852c0d0013107833" }/>
                        <meta name="US" content="US" />
                    </Helmet>
                    : null
            } */}

            <Helmet>
                {
                    noIndex
                        ? <meta name="robots" content="noindex, follow" />
                        : null
                }
                {/* <link rel="canonical" href={href && href[href.length - 1] === '/' ? href : `${href}/`} /> */}
                <link rel="canonical" href={`https://www.lipton.com${location.pathname}`}></link>
                {/* <link rel="alternate" hreflang="x-default" href={`https://www.lipton.com${location.pathname}`}></link> */}
                {
                hrefLangs.map(
                    ({slug, node_locale}, index)=>{
                        if(node_locale === 'en-US' && slug!=='/'){
                            return (
                                <link rel="alternate" hreflang="x-default" href={`https://www.lipton.com/us/en/${slug}`}></link>
                            )
                        }else if (node_locale === 'en-US' && slug=='/'){
                            return (
                                <link rel="alternate" hreflang="x-default" href={`https://www.lipton.com/us/en/`}></link>
                            )
                        }else{
                            null
                        }
                    }
                )    
            }
            {locale === 'en-US' && <link rel="alternate" hreflang="x-default" href={`https://www.lipton.com${location.pathname}`}></link>}
                {
                    hrefLangs.map(({ slug, node_locale }, index) => {
                        return slug === '/'
                            ? node_locale && siteUrl[node_locale] && (<link key={index} rel="alternate" title={mapLangSelector[node_locale]} hrefLang={node_locale} href={`https://www.lipton.com/${siteUrl[node_locale]}/`} />)
                            : slug[slug.length - 1] === '/'
                                ? node_locale && siteUrl[node_locale] && (<link key={index} rel="alternate" title={mapLangSelector[node_locale]} hrefLang={node_locale} href={`https://www.lipton.com/${siteUrl[node_locale]}/${slug}`} />)
                                : node_locale && siteUrl[node_locale] && (<link key={index} rel="alternate" title={mapLangSelector[node_locale]} hrefLang={node_locale} href={`https://www.lipton.com/${siteUrl[node_locale]}/${slug}/`} />)
                    })
                }
                {/* {
                    baseUrl == 'ca/fr' ||baseUrl == 'ca/en' ?
                     location.pathname == '/ca/fr/' || location.pathname == '/ca/en/' ? 
                    <link rel="alternate" title='France (French)' hrefLang='fr-FR' href='https://www.liptonteas.com/fr/' />
                    : 
                    <link rel="alternate" title='France (French)' hrefLang='fr-FR' href='https://www.liptonteas.com/fr/nos-marques/portfolio/lipton/' />
                    : null
                }  */}
                <link rel="alternate" title={mapLangSelector[locale]} hrefLang={locale} href={`https://www.lipton.com${location.pathname}`} />

                {
                    <link rel="preconnect"  href="https://images.ctfassets.net" />
                }
            </Helmet>


        </>
    )
}

Seo.defaultProps = {
    lang: `en`,
    meta: [],
    description: ``,
}

Seo.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
}

export default Seo
