import React from "react";
import { useSiteQuery } from "../../../hooks";
import { RichText } from "../../Layout/RichText/RichText"
import * as styles from './section-sign-up.module.scss';

const SectionSignUp = ({ }) => {
    const {
        signUpBackgroundImage,
        signUpCondition,
        signUpFirstName,
        signUpCondition1,
        signUpCondition2,
        signUpCondition3,
        signUpEmail,
        signUpHeading,
        signUpLastName,
        signUpSubHeading,
        signUpSubmit
    } = useSiteQuery()

    return (
        <div className={styles.wrapper}>
            <div className={styles.form} style={{ backgroundImage: `url(${signUpBackgroundImage?.fluid?.src})` }}>
                <div className={styles.formContent}>
                    <div className={styles.formTop}>
                        <h3 className={styles.heading}>{signUpHeading}</h3>
                        <p className={styles.subHeading}>{signUpSubHeading}</p>
                    </div>
                    <div className={styles.content}>
                        <div className={styles.signUpContent}>
                            <div className={styles.signUpContentContainer}>
                                <form>
                                    <div className={styles.formInput}>
                                        <div className={styles.cell}>
                                            <input type="text" placeholder={signUpFirstName} required  />
                                        </div>
                                        <div className={styles.cell}>
                                            <input type="text" placeholder={signUpLastName} required />
                                        </div>
                                    </div>
                                    <div className={styles.formLayout}>
                                        <div className={styles.formEmail}>
                                            <input type="text" placeholder={signUpEmail} required />
                                        </div>
                                        <div className={styles.formInput}>
                                        <div className={styles.cell}>
                                     <input type="text" placeholder="Zip Code" required/>
                                        </div>
                                        </div>
                                        <div className={styles.formInput}>
                                        <div className={styles.cell}>
                                        <input type="date" id="birthday" name="birthday"></input>
                                        </div>
                                        </div>
                                        {/* <div className={styles.formCheckbox}>
                                            <input type="checkbox" name="" id="one" required >

                                            </input>
                                            <label htmlFor="one" className={styles.checkboxLabel}>
                                                <span>{signUpCondition1}</span>
                                            </label>
                                        </div>
                                        <div className={styles.formCheckbox}>
                                            <input type="checkbox" name="" id="two" required />
                                            <label htmlFor="two" className={styles.checkboxLabel}>
                                                <span>{signUpCondition2}</span>
                                            </label>
                                        </div>
                                        {/* <div className={styles.formCheckbox}> */}
                                            {/* <input type="checkbox" name="" id="three" required />
                                            <label htmlFor="three" className={styles.checkboxLabel}>
                                                <span>{signUpCondition3}</span>
                                            </label>
                                        </div>  */}
                                        {
                                            signUpCondition &&
                                            <div className={styles.SignUpCondition}> <RichText content={signUpCondition} /> </div>
                                        }
                                        <div className={styles.submit}>
                                            <button className={styles.submitButton}>{signUpSubmit}</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SectionSignUp