import React, {useState, useEffect } from "react"
import * as styles from "./componentSignup.module.scss";
import { useSiteQuery } from "../../../hooks";
 import Spinner from '../../Spinner/Spinner'
export default function ComponentSignup() {

  const {
    signUpBackgroundImage,
    signUpCondition,
    signUpFirstName,
    signUpCondition1,
    signUpCondition2,
    signUpCondition3,
    signUpEmail,
    signUpHeading,
    signUpLastName,
    signUpSubHeading,
    signUpSubmit
} = useSiteQuery()

  const [loading, setLoading] = useState(false) 
  useEffect(() => {
    const container = document.getElementById("signupForm")
    const signUp = document.createElement("script")
    signUp.src =
      "//r1.dotdigital-pages.com/resources/sharing/embed.js?sharing=lp-embed&domain=r1.dotdigital-pages.com&id=7HZU-2AH%2F%22"
    signUp.async = true
    container?.appendChild(signUp)
    return () => {container?.removeChild(signUp)}

  })
  useEffect(() =>{
    const timer = setTimeout(() => {
      if (document.getElementById('7HZU-2AH') === null) {
         window.location.reload()
      }
      return () => clearTimeout(timer)
    }, 4000)
}, [])

useEffect(() =>{
  const interval = setInterval(() =>{
    if (document.getElementById('7HZU-2AH') === null) {
      setLoading(true)
      if(!document.getElementById("para")){
        const para = document.createElement("p");
        para.innerHTML = "Loading ...";
        para.id = 'para'
        para.classList.add(styles.para)
        document.getElementById("form-container")?.appendChild(para);
      }
    }else{
      setLoading(false)
    }
  }, 1000)
  return () => clearInterval(interval)
}, [])

  return (
    <div className={styles.wrapper} id = 'form-container'>
      <div className={styles.formBg} style={{ backgroundImage: `url(${signUpBackgroundImage?.fluid?.src})` }}>
      <div className={styles.formContent}>
        {loading ? <Spinner /> : ''}
        <div id="signupForm" className={styles.form}>

        </div>
      </div>
      </div>
    </div>
  )
}